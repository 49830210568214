import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from "react-markdown/with-html";
import slugify from '../../utils/slugify';

import Container from '../container/container';

import './services-list.scss';
import Link from '../link';

const ServicesList = () => {
    const data = useStaticQuery(graphql`
        query {
            services: allStrapiServices {
                nodes {
                    serviceDisplayName
                    serviceName
                    id
                }
            }
        }`)
    return (
        <div className="services-list">
            <Container>
                <div className="services-wrapper">
                {
                    data.services.nodes.map(service => (
                        <Link to={`/oferta/${slugify(service.serviceName)}`} key={service.id}>
                            <div className="service">
                                <div className="service-name">
                                    <span><ReactMarkdown escapeHtml={false} source={service.serviceDisplayName} /></span>
                                </div>
                            </div>
                        </Link>
                    ))
                }
                    {/* <div className="service">
                        <div className="service-name">
                           <span><strong>Doradztwo</strong></span>
                        </div>
                    </div>
                    <div className="service">
                        <div className="service-name">
                            <span>Kontrole i postępowania <strong>podatkowe</strong></span>
                        </div>
                    </div>
                    <div className="service">
                        <div className="service-name">
                            <span>Zakładanie działalności <strong>gospodarczych</strong></span>
                        </div>
                    </div>
                    <div className="service">
                        <div className="service-name">
                            <span>Kadry i <strong>płace</strong></span>
                        </div>
                    </div>
                    <div className="service">
                        <div className="service-name">
                            <span>Audyt <strong>podatkowy</strong></span>
                        </div>
                    </div>
                    <div className="service">
                        <div className="service-name">
                            <span>Analizy <strong>finansowe i controling</strong></span>
                        </div>
                    </div> 
                    <div className="service">
                        <div className="service-name">
                           <span><strong>Szkolenia</strong></span> 
                        </div>
                    </div> */}
                </div>
            </Container>
        </div>
    )
}

export default ServicesList;
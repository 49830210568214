import React from 'react';

import SubpageHeader from '../subpage-header/subpage-header';
import ServicesList from '../services/services-list';

import './services.scss'
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const Services = () => {
    const data = useStaticQuery(graphql`
        {
            servicesBg: file(relativePath: {eq: "services_bg.jpg"}) {
                id
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <BackgroundImage Tag="section" className="services" fluid={data.servicesBg.childImageSharp.fluid}>
            <SubpageHeader title="Oferta" subtitle="Zapoznaj się z naszą ofertą"/>
            <ServicesList />
        </BackgroundImage>
    )
}

export default Services;
import React from "react"

import Layout from '../components/layout';
import Contact from '../components/contact/contact';
import Services from '../components/services/services';
import Banner from '../components/banner/banner';

const ServicesPage = () => {
    return (
        <Layout title="Oferta">
            <Services />
            <Banner >
                Masz pytania dotyczące oferty? Zadzwoń już teraz <strong>+48 509 94 88 11</strong>
            </Banner>
            <Contact />
        </Layout>
    )
}

export default ServicesPage;